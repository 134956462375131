import React from "react";
import { Container } from "react-bootstrap";
import getImagePath from "helpers/get-image-path";

import {
  twoColumns, twoColumnsItems, twoColumnsText,
  twoColumnsImg, itemsReverse, twoColumnsItemsReverse,
  mediaTwoColumns
} from "./style.mod.scss";
import RichText from "components/rich-text";

const TwoColumns = ({ data }) => {

  const image = data.image;
  const alternating = data.alternating;
  const itemsReverseState = data.overlap;
  const mediaTwoColumnsVersion = data.leftAlign;

  return (
    <div
      className={`
        ${twoColumns}
        ${(!(mediaTwoColumnsVersion === undefined || mediaTwoColumnsVersion === false))? mediaTwoColumns : ""}
        ${(!(itemsReverseState === undefined || itemsReverseState === false))? itemsReverse : ""}
        ${(!(alternating === undefined || alternating === false))? twoColumnsItemsReverse : ""}
      `}
      style={{ backgroundColor: data.bgColor && data.bgColor.hex }}
    >
      <Container className="text-center">
        <div className={twoColumnsItems}>
          <div className={twoColumnsText}>
            <RichText blocks={data.text} />
          </div>
          <div className={twoColumnsImg}>
            {image &&
              <img src={getImagePath(image)} alt={image?.alt} />
            }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TwoColumns;
